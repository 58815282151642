<template>
    <v-main class="main-bg-white">
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>

        <div class="main-block">
            <section class="main-section">
                <div class="main-title">
                    <h2 class="main-h2"><strong class="p-bold-ev">ZEITGARD PRO</strong> <span class="p-ev-light">КРАСОТУ</span></h2>
                    <h3 class="main-h3">И МОЛОДОСТЬ В ЛЮБОМ ВОЗРАСТЕ</h3>
                    <p>Инновационный уход за лицом, без косметологических салонов, доступный каждому</p>
                </div>
                <img src="/img/zg/zg-circle-text.png" class="main-circle" />
                <div style="text-align: center;">
                    <img src="/img/zg/zg-pro-girl.png" alt="" class="zg-pro-girl">
                </div>
            </section>

            <section class="mt-15 zg-section-text">
                <h2 class="zg-h2-title">ЕСЛИ ТЫ ХОЧЕШЬ</h2>
                <v-row class="zg-text-block">
                    <v-col cols="12" sm="6" class="zg-col-first">
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            УВИДЕТЬ ЭФФЕКТ УЖЕ ПОСЛЕ ПЕРВОЙ ПРОЦЕДУРЫ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            НЕ ТРАТИТЬ ДЕНЬГИ И ВРЕМЯ НА КОСМЕТОЛОГОВ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            РАЗГЛАДИТЬ МОРЩИНЫ И УЛУЧШИТЬ ЦВЕТ ЛИЦА
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            УБРАТЬ ЦЕЛЛЮЛИТ С ПРОБЛЕМНЫХ МЕСТ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            ВЫГЛЯДЕТЬ МОЛОДО И ПОДТЯНУТО КРУГЛЫЙ ГОД
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            МЕТОД ДОПОЛНИТЕЛЬНОГО ЗАРАБОТКА НА ДОМУ
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            СОВРЕМЕННЫЙ ВАРИАНТ ЕЖЕДНЕВНОГО УХОДА ЗА КОЖЕЙ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            СНЯТЬ НАПРЯЖЕНИЕ И УСТАЛОСТЬ С МЫШЦ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            ЭФФЕКТИВНЫЙ СПОСОБ БОРЬБЫ С УГРЕВОЙ СЫПЬЮ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            ПОЛНЫЙ СПЕКТР СОПУТСТВУЮЩИХ КОСМЕТИЧЕСКИХ СРЕДСТВ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            ГЛУБОКОЕ УЛЬТРАЗВУКОВОЕ ОЧИЩЕНИЕ
                        </p>
                        <p>
                            <img src="/img/zg/check-blue.png" class="zg-check">
                            УВЕЛИЧИТЬ В 2 РАЗА ДОХОД В БЬЮТИ-СФЕРЕ (ДЛЯ БЬЮТИ МАСТЕРОВ)
                        </p>  
                    </v-col>
                </v-row>
            </section>

            <section class="zg-want zg-section-text">
                <h2 class="zg-h2-title">Хочу</h2>
                <v-row>
                    <v-col class="zg-want-one">
                        <img src="/img/zg/zg-arrow-left.png" class="zg-arrow-left" />
                        <h3 class="zg-h3-title">Свой косметолог на дому</h3>
                        <button @click="scrollToBeauty()"><img src="/img/zg/btn-zg-beauty.png" class=""></button>
                    </v-col>
                    <v-col class="zg-want-two">
                        <img src="/img/zg/zg-arrow-right.png" class="zg-arrow-right" />
                        <h3 class="zg-h3-title">Хочу увеличить доход</h3>
                        <button @click="scrollToMoney()"><img src="/img/zg/btn-zg-money.png" class=""></button>
                    </v-col>
                </v-row>
                <img src="/img/zg/zg-pack.png" class="zg-pack-img">
            </section>

            <section id="zg-beauty" class="zg-section-text">
                <h2 class="zg-h2-title">ZEITGARD PRO <span style="text-transform: lowercase;">это:</span></h2>
                <div class="zg-video">
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <iframe
                                src="https://fast.wistia.net/embed/iframe/n8b9i5auvq?seo=false&videoFoam=true"
                                title="Анонс линейки Zeitgard Pro Video"
                                allow="autoplay; fullscreen"
                                allowtransparency="true"
                                frameborder="0"
                                scrolling="no"
                                class="wistia_embed"
                                name="wistia_embed"
                                msallowfullscreen
                                width="100%"
                                height="100%"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section class="zg-section-text">
                <v-row class="zg-text-block-blue">
                    <v-col cols="12" sm="6">
                        <div class="zg-col-blue-first">
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                <strong class="zg-strong-mobile">запатентованные</strong> инновационные технологии
                            </p> 
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                в <strong class="zg-strong-mobile">10 раз эффективнее умывания</strong>
                            </p>
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                современный <strong class="zg-strong-mobile">эргономичный дизайн</strong>
                            </p>
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                проверенная <strong class="zg-strong-mobile">технология осцилляции</strong>
                            </p>
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                для <strong class="zg-strong-mobile">любого типа</strong> кожи и <strong class="zg-strong-mobile">ежедневного применения</strong>
                            </p>
                        </div>                        
                    </v-col>
                    <v-col cols="12" sm="6">
                        <div class="zg-col-blue-second">
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                <strong class="zg-strong-mobile">антивозрастной уход</strong> за счет <strong class="zg-strong-mobile">ультразвука</strong>
                            </p> 
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                <strong class="zg-strong-mobile">4 вида насадки</strong> и <strong class="zg-strong-mobile">широкий спектр</strong> применения
                            </p>
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                <strong class="zg-strong-mobile">компактный и простой</strong> в использовании
                            </p>
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                возможность ухода <strong class="zg-strong-mobile">за кожей тела</strong>
                            </p>
                            <p>
                                <img src="/img/zg/check-grey.png" class="zg-check">
                                <strong class="zg-strong-mobile">никаких побочных</strong> эффектов
                            </p>  
                        </div>                                              
                    </v-col>
                </v-row>
                <div style="text-align: center;" class="mt-10 pb-7">
                    <button @click="scrollToForm()"><img src="/img/zg/btn-zg-want-beauty.png" class=""></button>
                </div>
            </section>

            <section class="zg-section-text" id="zg-money">
                <h2 class="zg-h2-title">
                    ПОМОГАЙ БЫТЬ КРАСИВОЙ <br />
                    И ЗАРАБАТЫВАЙ ДЕНЬГИ
                </h2>
                <div class="zg-video">
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                      <iframe
                        src="https://fast.wistia.net/embed/iframe/77ruszxhzb?seo=false&videoFoam=true" 
                        title="LR ZEITGARD PRO. КРАСОТА В ЛЮБОМ ВОЗРАСТЕ Video" 
                        allow="autoplay; fullscreen" 
                        allowtransparency="true" 
                        frameborder="0" 
                        scrolling="no" 
                        class="wistia_embed" 
                        name="wistia_embed" 
                        msallowfullscreen 
                        width="100%" 
                        height="100%"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
                <h3 class="zg-h3-title text-center">ПРИБОР ОЧЕНЬ ПРОСТ В ЭКСПЛУАТАЦИИ И НЕ ТРЕБУЕТ ОСОБЫХ ПРОФЕССИОНАЛЬНЫХ НАВЫКОВ ИЛИ СПЕЦИАЛЬНОГО ОБРАЗОВАНИЯ</h3>
                <v-row class="zg-money-block">
                    <v-col cols="12" sm="6">
                        <h3>ОТКРОЙ СВОЁ ДЕЛО</h3>
                        <img src="/img/zg/zg-money.png" class="zg-money-left" />
                        <p>Ты можешь не только окупить стоимость прибора, но и иметь постоянный дополнительный заработок, делая бьюти процедуры своим знакомым. Студия красоты в комфорте собственного дома.</p>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <h3>УВЕЛИЧЬ СВОЙ ДОХОД</h3>
                        <img src="/img/zg/zg-money2.png" class="zg-money-right" />
                        <p>Если ты уже работаешь в бьюти сфере, ты можешь ввести бьюти процедуры для своих клиентов или рекомендовать к покупке сам прибор,  используя этот сайт.</p>
                    </v-col>
                </v-row>
            </section>

            <section class="zg-section-text">
                <h2 class="zg-h2-blue">СВЯЖИСЬ СО МНОЙ</h2>
                <h3 class="zg-h3-blue">
                    И ПОЛУЧИ ИНСТРУКЦИИ ПО ЭКСПЛУАТАЦИИ <br />
                    И СОПРОВОЖДЕНИЕ ПО ТЕМЕ "КАК УВЕЛИЧИТЬ СВОЙ ДОХОД"
                </h3>
                <div style="text-align: center;">
                    <button @click="scrollToForm()"><img src="/img/zg/btn-zg-want-money.png" class=""></button>
                </div>
            </section>

            <section class="zg-section-text">
                <h2 class="zg-h2-blue">ПРОИЗВОДИТЕЛЬ - LR HEALTH & BEAUTY</h2>
                <h3 class="zg-h3-blue">НЕМЕЦКОЕ КАЧЕСТВО, ПОДТВЕРЖДЕННОЕ ВРЕМЕНЕМ</h3>
                
                <div class="zg-video">
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <iframe
                                src="https://fast.wistia.net/embed/iframe/36f2gbutpx?seo=false&videoFoam=true"
                                title="whatsapp_video_2023-03-02_at_233503"
                                allow="autoplay; fullscreen"
                                allowtransparency="true"
                                frameborder="0"
                                scrolling="no"
                                class="wistia_embed"                            
                                name="wistia_embed"
                                msallowfullscreen
                                width="100%"
                                height="100%"
                            ></iframe>
                        </div>
                    </div>
                </div>                
                <div class="zg-about-lr">
                    <p>Компания LR Health & Beauty (Германия) основана в 1985, и на протяжении 38 лет является конкурентным игроком в сфере прямых продаж.</p>
                    <p>Каталог продукции LR Health & Beauty насчитывает более 700 наименований и представлен в 28 странах мира.</p>
                    <p>Уходовая косметика и аппараты по уходу за лицом и телом не имеют аналогов в сфере бьюти индустрии и косметологии, что подтверждено институтом Фрезениус, Дерматест, клиникой Шарите и Международным научным комитетом по алоэ (IASC).</p>
                    <p>Эти организации регулярно проводят тестирования и клинические исследования, подтверждая высокое качество и эксклюзивность продукции LR Health & Beauty.</p>
                </div>                
            </section>

            <section class="zg-section-text">
                <h2 class="zg-h2-blue">ВЫБОР ЭКСПЕРТОВ</h2>
                <v-row class="zg-video-twice">
                    <v-col cols="12" md="6">
                        <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                            <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                <iframe
                                    src="https://fast.wistia.net/embed/iframe/2cdaedviun?seo=false&videoFoam=true"
                                    title="Д-р Вернер Фосс, основатель и руководитель НИИ Dermatest о LR Zeitgard Pro Video"
                                    allow="autoplay; fullscreen"
                                    allowtransparency="true"
                                    frameborder="0"
                                    scrolling="no"
                                    class="wistia_embed"
                                    name="wistia_embed"
                                    msallowfullscreen
                                    width="100%"
                                    height="100%"
                                ></iframe>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                          <iframe
                            src="https://fast.wistia.net/embed/iframe/cjdt0qd6jt?seo=false&videoFoam=true" 
                            title="Эксперт, тренер-косметолог Ирина Бабахова о LR Zeitgard Pro - обзорное видео. Video" 
                            allow="autoplay; fullscreen" 
                            allowtransparency="true" 
                            frameborder="0" 
                            scrolling="no" 
                            class="wistia_embed" 
                            name="wistia_embed" 
                            msallowfullscreen 
                            width="100%" 
                            height="100%"
                          ></iframe>
                          </div>
                        </div>
                    </v-col>
                </v-row>
                <div class="zg-dermatest">
                    <div>
                        <p>УНИКАЛЬНАЯ ЗАПАТЕНТОВАННАЯ ТЕХНОЛОГИЯ</p>
                        <p>ОДОБРЕНО НИИ DERMATEST</p>
                    </div>                    
                    <img src="/img/zg/dermatest.png" />
                </div>
                
            </section>

            <section class="zg-section-text">
                <h2 class="zg-h2-title">ОТЗЫВЫ И РЕЗУЛЬТАТЫ</h2>
                <v-carousel
                    cycle
                    height="400"
                    hide-delimiter-background
                    show-arrows-on-hover
                >
                    <v-carousel-item
                        v-for="(slide, i) in slides"
                        :key="i"
                    >
                        <v-sheet
                            height="100%"
                        >
                            <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                            >
                                <div class="text-h2">
                                    <p style="width: 100%; max-width: 450px; height: auto">
                                        <img style="width: 100%;" :src=slide.src alt=""/>
                                    </p>
                                </div>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
                <p class="zg-otzivi-p mt-7">
                    100% УЧАСТНИКОВ ИССЛЕДОВАНИЯ ПОДТВЕРДИЛИ, <br />
                    ЧТО КОЖА СТАЛА БОЛЕЕ ГЛАДКОЙ И СВЕЖЕЙ ПОСЛЕ ПРОЦЕДУРЫ
                </p>
                <p class="zg-otzivi-p pb-10">
                    70% УЧАСТНИКОВ ИССЛЕДОВАНИЯ ПОДТВЕРЖДАЮТ ВИДИМОЕ УМЕНЬШЕНИЕ <br />
                    МОРЩИН И ИХ ГЛУБИНЫ ПОСЛЕ ЧЕТЫРЕХНЕДЕЛЬНОГО ПРИМЕНЕНИЯ
                </p>
            </section>

            <section class="zg-section-text" style="position: relative;">
                <h2 class="zg-h2-blue-bottom">
                    ZEITGARD PRO
                    <img src="/img/zg/logo-germany.png" />
                </h2>
                <h3 class="zg-h3-blue">
                    ПОЗВОЛЬ СЕБЕ БОЛЬШЕ КАЧЕСТВА <br />
                    И КРАСОТЫ В ЖИЗНИ
                </h3>
                <img src="/img/zg/zg-circle-text.png" class="bottom-circle" />
                <div style="text-align: center;" class="bottom-btn-beauty">
                    <button @click="scrollToForm()"><img src="/img/zg/btn-zg-want-beauty.png" class=""></button>
                </div>
                <h3 class="zg-h3-blue">
                    ТВОЯ КРАСОТА НЕ ЗАВИСИТ ОТ ВОЗРАСТА, <br />
                    ОНА ЯВЛЯЕТСЯ РЕЗУЛЬТАТОМ УХОДА ЗА КОЖЕЙ
                </h3>
            </section>

            <section v-if="userData.id != 127" class="zg-section-text" id="zg-form">
                <v-container class="text-center">
                    <!-- <h2 class="zg-h2-blue">Продли свою молодость!</h2> -->
                    <!-- <h3 class="zg-h3-blue">Оставь заявку</h3> -->
                    <h2 class="zg-h2-blue">Оставь заявку</h2>
                    <p class="zg-p-blue">
                      получи бесплатную консультацию и <br />
                      сопровождение по эффективному использованию прибора
                    </p>
                    <v-responsive
                        class="mx-auto mb-10"
                        max-width="600px"
                    >
                        <v-text-field
                            label="Введите свое имя"
                            prepend-icon="mdi-face-man"
                            type="text"
                            v-model.trim="newProspect.name"
                            :error-messages="nameErrors"
                            @input="$v.newProspect.name.$touch()"
                            @blur="$v.newProspect.name.$touch()"
                        />
                        <v-text-field
                            class="cell-input-phone"
                            label="Телефон"
                            prepend-icon="mdi-phone"
                            type="text"
                            v-model.trim="newProspect.phone"
                            :error-messages="phoneErrors"
                            @input="$v.newProspect.phone.$touch()"
                            @blur="$v.newProspect.phone.$touch()"
                        />
                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone[0] }}
                        </div>

                        <p style="text-align: center">
                            <v-btn
                                color="#40576c"
                                class="mr-0"
                                @click="addProspectZg"
                            >
                                Отправить
                            </v-btn>
                        </p>
                        <p class="zg-privacy-text">
                            Оставляя заявку, вы даете свое
                            <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                            и принимаете
                            <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                        </p>
                    </v-responsive>
                </v-container>
            </section>

            <!-- <section v-if="userData.id != 127" class="zg-section-text">
                <v-container class="text-center">
                    <h2 class="be-h2-center mt-7 mb-3">Продли свою молодость!</h2>
                    <p class="mb-5">Свяжись с персональным консультантом LR Health & Beauty прямо сейчас</p>

                    <v-responsive
                        class="mx-auto mb-10"
                        width="56"
                    >
                        <v-divider class="mb-1" style="border-color: #CA286C"></v-divider>

                        <v-divider style="border-color: #CA286C"></v-divider>
                    </v-responsive>

                    <v-card
                        class="v-card-profile v-card--material mt-16 pa-3 mx-auto"
                        max-width="430"
                    >
                        <div class="d-flex grow flex-wrap">
                            <div
                                class="v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                                style="height: 128px; min-width: 128px; width: 128px"
                            >
                                <div class="v-image v-responsive theme--light">
                                    <img v-if="userData.avatar"
                                        :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                        alt=""
                                    >
                                    <div class="v-responsive__content" style="width: 250px"></div>
                                </div>
                            </div>

                            <v-card-text class="text-center">
                                <h4 class="display-2 font-weight-light mb-3 black--text">
                                    {{ userData.name }} {{ userData.last_name }}
                                </h4>

                                <h6
                                    class="display-1 mb-3 green--text"
                                    v-if="userData.who_is"
                                >
                                    <v-icon class="mr-2 green--text">mdi-seal-variant</v-icon>
                                    {{ userData.who_is }}
                                </h6>

                                <p v-if="userData.country || userData.city">
                                    <span v-if="userData.country">{{ userData.country }}</span>
                                    <span v-if="userData.country && userData.city">, </span>
                                    <span v-if="userData.city">{{ userData.city }}</span>
                                </p>
                            </v-card-text>
                        </div>
                        <v-divider style="padding-bottom: 20px"/>
                        <v-row>
                            <v-col class="text-center">
                                <div class="input-group mb-3">
                                    <v-tooltip
                                        top
                                        v-if="userData.phone_whatsapp"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                style="text-decoration: none"
                                                :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                target="_blank"
                                            >
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    class="mr-4"
                                                    color="success"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-whatsapp</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <span>Связаться в WhatsApp</span>
                                    </v-tooltip>

                                    <v-tooltip
                                        top
                                        v-if="userData.telegram"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                style="text-decoration: none"
                                                :href="`${userData.telegram}`"
                                                target="_blank"
                                            >
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    class="mr-4"
                                                    color="blue"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>fab fa-telegram-plane</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <span>Связаться в Telegram</span>
                                    </v-tooltip>

                                    <v-tooltip
                                        top
                                        v-if="userData.phone_viber"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                :href="`viber://chat?number=${userData.phone_viber}`"
                                                target="_blank"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    class="mr-4"
                                                    color="deep-purple lighten-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>fab fa-viber</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <span>Связаться в Viber</span>
                                    </v-tooltip>

                                    <v-tooltip
                                        top
                                        v-if="userData.instagram"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                :href="`${userData.instagram}`"
                                                target="_blank"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    class="mr-4"
                                                    color="pink darken-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-instagram</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <span>Связаться в Instagram</span>
                                    </v-tooltip>

                                    <v-tooltip
                                        top
                                        v-if="userData.fb_messenger"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                :href="`${userData.fb_messenger}`"
                                                target="_blank"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    class="mr-4"
                                                    color="light-blue"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-facebook-messenger</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <span>Связаться в Facebook Messenger</span>
                                    </v-tooltip>

                                    <v-tooltip
                                        top
                                        v-if="userData.vkontakte"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                :href="`${userData.vkontakte}`"
                                                target="_blank"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    class="mr-4"
                                                    color="blue darken-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>fab fa-vk</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <span>Связаться во VKontakte</span>
                                    </v-tooltip>

                                    <v-tooltip
                                        top
                                        v-if="userData.odnoklassniki"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                :href="`${userData.odnoklassniki}`"
                                                target="_blank"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    class="mr-4"
                                                    color="orange darken-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-odnoklassniki</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        <span>Связаться в Одноклассники</span>
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
            </section> -->

            <v-footer
                class="justify-center be-footer"
                height="100"
            >
                <div class="title font-weight-light text-center">
                    &copy; {{ (new Date()).getFullYear() }} — newlvl.net — независимый партнер LR Health & Beauty
                </div>
            </v-footer>
        </div>

        <v-dialog
            v-model="dialog_prospect"
            width="500"
        >
            <v-card>
                <v-card-title class="headline lighten-2 pb-5" style="background-color: #2899ca; color: white">
                    Заявка принята
                </v-card-title>

                <v-card-text>
                    Ваш консультант свяжется с вами в ближайшее время
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue"
                        text
                        @click="dialog_prospect = false"
                    >
                        Хорошо
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

export default {
    name: "ZeitGard",
    data: () => ({
        newProspect: {
            name: '',
            phone: '',
            partner: '',
            instrument: 'ZeitGard',
            step: 'Новый'
        },
        slides: [
            {
                src: '/img/zg/otzivi/1.jpg',
            },
            {
                src: '/img/zg/otzivi/2.jpg',
            },
            {
                src: '/img/zg/otzivi/3.jpg',
            },
            {
                src: '/img/zg/otzivi/4.jpg',
            },
            {
                src: '/img/zg/otzivi/5.jpg',
            },
            {
                src: '/img/zg/otzivi/6.jpg',
            },
            {
                src: '/img/zg/otzivi/7.jpg',
            },
            {
                src: '/img/zg/otzivi/8.jpg',
            },
            {
                src: '/img/zg/otzivi/9.jpg',
            },
            {
                src: '/img/zg/otzivi/10.jpg',
            },
            {
                src: '/img/zg/otzivi/11.jpg',
            },
            {
                src: '/img/zg/otzivi/12.jpg',
            },
            {
                src: '/img/zg/otzivi/13.jpg',
            },
            {
                src: '/img/zg/otzivi/14.jpg',
            },
            {
                src: '/img/zg/otzivi/15.jpg',
            },
            {
                src: '/img/zg/otzivi/16.jpg',
            },
            {
                src: '/img/zg/otzivi/17.jpg',
            },
        ],
        errors: {},
        dialog_main: false,
        dialog_prospect: false,
        loading: false,
        cookieShow: true,
    }),
    metaInfo: {
        title: 'ZeiTGard Pro',
        meta: [
            { vmid: 'description', property: 'description', content: 'Революция в сфере красоты - ZeitGard PRO' },
            { vmid: 'og:title', property: 'og:title', content: 'ZeiTGard Pro' },
            { vmid: 'og:description', property: 'og:description', content: 'Революция в сфере красоты - ZeitGard PRO' },
            { vmid: 'og:image', property: 'og:image', content: '/img/zg/zeitgard.png' },
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'ZeitGard', query: { partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUser'
        }),
        dataUser() {
            return this.userData
        },
        nameErrors() {
            const errors = []
            if (!this.$v.newProspect.name.$dirty) return errors
            !this.$v.newProspect.name.minLength && errors.push('Минимум 2 буквы')
            !this.$v.newProspect.name.maxLength && errors.push('Максимум 30 букв')
            !this.$v.newProspect.name.required && errors.push('Обязательно для заполнения')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.newProspect.phone.$dirty) return errors
            !this.$v.newProspect.phone.numeric && errors.push('Только цифры')
            !this.$v.newProspect.phone.minLength && errors.push('Минимум 5 цифр')
            !this.$v.newProspect.phone.maxLength && errors.push('Максимум 30 цифр')
            !this.$v.newProspect.phone.required && errors.push('Обязательно для заполнения')
            return errors
        }
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showUser: 'user/showUserPage',
            createProspect: 'user/addClient'
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        scrollToMoney() {
            let scrollBottom = document.getElementById("zg-money")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        scrollToBeauty() {
            let scrollBottom = document.getElementById("zg-beauty")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        scrollToForm() {
            let scrollBottom = document.getElementById("zg-form")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        addProspectZg() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                this.createProspect(this.newProspect)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loading = false
                            this.dialog_prospect = true
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
    },
    mounted () {
        this.showUser({user: this.$route.query.partner, inst: this.$route.query.inst})
        this.newProspect.partner = this.$route.query.partner
        this.newProspect.inst = this.$route.query.inst
    },
    validations: {
        newProspect: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            phone: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(30)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Evolventa";
  src: url('/fonts/Evolventa-Regular.otf');
}
@font-face {
  font-family: "Evolventa-Bold";
  src: url('/fonts/Evolventa-Bold.otf');
}
.main-bg-white {
    background-color: #fff !important;
    font-family: "Evolventa";
    color: #555;
}
.p-bold-ev {
    font-family: "Evolventa-Bold";
}
.p-ev-light {
    font-weight: 200;
}
.main-block {
    background: url(/img/zg/zg-bg.jpg) repeat-y;
    max-width: 1200px;
    margin: 0 auto;
    background-size: contain;
}
.main-title {
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: center;
}
.main-h2 {
    font-size: 66px;
    line-height: 60px;
}
.main-h3 {
    font-size: 42px;
    font-weight: 300;
}
.main-title p {
    font-size: 18px;
}
.main-section {
    position: relative;
}
.main-section .zg-zg {
    max-width: 300px;
    margin-left: 10%;
}
.main-circle {
    display: inline-block;
    position: absolute;
    text-align: center;
    width: 200px;
    height: 200px;
    background-image: url(/img/zg/zg-circle.png);
    background-repeat: no-repeat;
    background-size: contain;
    left: 40%;
}
.main-circle p {
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    padding-top: 25%;
}
.bottom-circle {
    display: inline-block;
    position: absolute;
    text-align: center;
    width: 200px;
    height: 200px;
    background-image: url(/img/zg/zg-circle.png);
    background-repeat: no-repeat;
    background-size: contain;
    right: 80px;
    top: 40px;
}
.bottom-circle p {
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    padding-top: 25%;
}
.bottom-btn-beauty {
    margin-bottom: 30px;
}
.main-section .zg-girl {
    max-width: 500px;
    position: absolute;
    right: 0;
    top: 15%;
}
.main-section .zg-pro-girl {
    max-width: 1000px;
    height: auto;
    width: 100%;
}
.zg-h2-title {
    font-size: 48px;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    text-transform: uppercase;
}
.zg-section-text {
    background-color: rgb(255 255 255 / 75%);
}
.zg-text-block {
    padding: 0 100px;
}
.zg-text-block p {
    font-size: 22px;    
    padding-left: 60px;
    position: relative;
}
.zg-text-block p img {
    position: absolute;
    left: 0;
    top: 5px;
}
.zg-text-block-blue {
    padding: 40px 50px 0;
}
.zg-text-block-blue p {
    font-size: 22px;
    padding-bottom: 10px;
    padding-left: 60px;
    position: relative;
}
.zg-text-block-blue p img {
    position: absolute;
    left: 0;
    width: 35px;
}
.zg-col-first {
    padding-right: 40px;
}
.zg-h3-title {
    font-size: 32px;
    font-weight: 200;
    text-transform: uppercase;
    // margin-top: 20px;
    margin-bottom: 30px;
}
.zg-want {
    text-align: center;
}
.zg-arrow-left {
    position: absolute;
    right: 150px;
    top: -35px;
    width: 65px;
}
.zg-arrow-right {
    position: absolute;
    left: 150px;
    top: -35px;
    width: 65px;
}
.zg-want-one {
    position: relative;
}
.zg-want-two {
    position: relative;
}
.zg-pack-img {
    max-width: 600px;
    width: 100%;
    height: auto;
}
.zg-col-blue-first {
    background-color: #d3e8fc;
    padding: 20px 40px;
    border-radius: 0 50px;
    height: 100%;
}
.zg-col-blue-second {
    background-color: #d3e8fc;
    padding: 20px 40px;
    border-radius: 50px 0;
    height: 100%;
}
.zg-money-block h3 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
}
.zg-money-block img {
    max-width: 250px;
    display: block;
    margin: 0 auto;
}
.zg-money-block p {
    font-size: 24px;
    line-height: 36px;
    padding: 30px 0 0 30px;
}
.zg-h2-blue {
    font-size: 42px;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: #40576c;
}
.zg-h2-blue-bottom {
    font-size: 48px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: #40576c;
}
.zg-h2-blue-bottom img {
    width: 30px;
    vertical-align: top;
}
.zg-h3-blue {
    font-size: 28px;
    text-align: center;
    padding-bottom: 40px;
    text-transform: uppercase;
    color: #40576c;
}
.zg-video {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 40px;
}
.zg-about-lr {
    padding: 0 80px 30px;
}
.zg-about-lr p {
    font-size: 18px;
}
.zg-video-twice {
    padding: 0 40px;
}
.zg-dermatest {
    padding: 40px;
}
.zg-dermatest div {
    display: inline-block;
    vertical-align: top;
}
.zg-dermatest p {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
}
.zg-dermatest img {
    width: 250px;
    margin-left: 40px;
}
.zg-otzivi-p {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}
.zg-p-blue {
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1200 - 320)));
  color: #40576c;
}
.zg-privacy-text {
    color: #333 !important;
    font-size: 10px;
    line-height: 15px !important;
    text-align: center;
}
.zg-privacy-text a {
    color: #333 !important;
}

@media(max-width: 1000px) {
    .zg-text-block {
        padding: 0 40px;
    }
    .zg-text-block p {
        font-size: 18px;
    }
    .zg-h3-title {
        font-size: 24px;
    }
    .bottom-circle {
        width: 180px;
        height: 180px;
        right: 10px;
    }
    .zg-h2-title {
        font-size: 42px;
    }
    .zg-money-block p {
        font-size: 20px;
    }
}

@media(max-width: 800px) {
    .main-block {
        background: url(/img/zg/zg-bg-1000.jpg) repeat-y;
    }
    .main-h2 {
        font-size: 52px;
    }
    .main-h3 {
        font-size: 32px;
    }
    .main-circle {
        width: 160px;
        height: 160px;
    }
    .zg-h2-title {
        font-size: 36px;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .zg-text-block {
        padding: 0 20px;
    }
    .zg-col-first {
        padding-right: 20px;
    }
    .zg-text-block p {
        font-size: 16px;
    }
    .zg-arrow-left {
        right: 100px;
        top: -20px;
        width: 55px;
    }
    .zg-arrow-right {
        left: 100px;
        top: -20px;
        width: 55px;
    }
    .zg-h3-title {
        font-size: 20px;
    }
    .zg-video {
        max-width: 600px;
    }
    .zg-text-block-blue {
        padding-left: 20px;
        padding-right: 20px;
    }
    .zg-text-block-blue p {
        font-size: 18px;
        padding-left: 50px;
        padding-bottom: 0;
    }
    .zg-col-blue-first, .zg-col-blue-second {
        padding-left: 20px;
        padding-right: 20px;
    }
    .zg-money-block h3 {
        font-size: 24px;
    }
    .zg-money-block p {
        font-size: 18px;
        line-height: 26px;
    }
    .zg-h2-blue {
        font-size: 36px;
    }
    .zg-h3-blue {
        font-size: 24px;
    }
    .bottom-circle {
        display: none;
    }
}

@media(max-width: 600px) {
    .main-block {
        background: url(/img/zg/zg-bg-800.jpg) repeat-y;
    }
    .main-title {
        padding-top: 30px;
    }
    .main-h2 {
        font-size: 42px;
    }
    .main-h3 {
        font-size: 26px;
    }
    .main-title p {
        padding: 0 10px;
    }
    .main-circle {
        width: 130px;
        height: 130px;
    }
    .zg-text-block p img {
        width: 30px;
        top: 0;
    }
    .zg-text-block p {
        padding-left: 45px;
    }
    .zg-h3-title {
        font-size: 16px;
    }
    .zg-video {
        max-width: 450px;
    }
    .zg-text-block-blue p img {
        width: 30px;
    }
    .zg-about-lr {
        padding-left: 20px;
        padding-right: 20px;
    }
    .zg-dermatest p {
        font-size: 18px;
    }
    .zg-dermatest {
        text-align: center;
    }
    .zg-dermatest img {
        margin: 0;
    }
    .zg-otzivi-p {
        font-size: 18px;
    }
}

@media(max-width: 480px) {
    .main-title {
        padding-top: 20px;
    }
    .main-h2 {
        font-size: 32px;
        line-height: 40px;
    }
    .main-h3 {
        font-size: 22px;
    }
    .main-circle {
        width: 80px;
        height: 80px;
    }
    .zg-h2-title {
        font-size: 28px;
    }
    .zg-col-first {
        padding-bottom: 0;
    }
    .zg-text-block .col-12 {
        padding-top: 3px;
    }
    .zg-text-block p {
        font-size: 15px;
        padding-left: 40px;
        line-height: 24px;
    }
    .zg-text-block p img {
        width: 25px;
        top: 5px;
    }
    .zg-arrow-left {
        right: 60px;
        top: -15px;
        width: 50px;
    }
    .zg-arrow-right {
        left: 60px;
        top: -15px;
        width: 50px;
    }
    .zg-want-two .zg-h3-title {
        margin-bottom: 50px;
    }
    .zg-strong-mobile {
        font-weight: 400;
    }
    .zg-h2-blue {
        font-size: 28px;
    }
    .zg-h3-blue {
        font-size: 18px;
    }
}
@media(max-width: 423px) {
    .zg-arrow-left, .zg-arrow-right {
        display: none;
    }
    .zg-want-two .zg-h3-title {
        margin-bottom: 30px;
    }
    .zg-h3-blue {
        font-size: 16px;
    }
}

@media(max-width: 360px) {
    .main-title {
        padding-top: 20px;
    }
    .main-h2 {
        font-size: 26px;
    }
    .main-h3 {
        font-size: 18px;
    }
    .main-title p {
        font-size: 14px;
    }
    .zg-h2-title {
        font-size: 26px;
    }
    .zg-arrow-left, .zg-arrow-right {
        display: none;
    }
    .zg-h3-title {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .zg-want-two .zg-h3-title {
        margin-bottom: 20px;
    }
    #zg-money .zg-h3-title {
        font-size: 18px;
    }
    .zg-h2-blue {
        font-size: 26px;
    }
    .zg-video-twice {
        padding: 0;
    }
    .zg-h2-blue-bottom {
        font-size: 36px;
    }
    
}
</style>